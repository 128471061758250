/* Define custom variables for colors */
:root {
  /* App theme colors */
  --primary-color: #8F2D56;
  --secondary-color: #D81159;
  --tertiary-color: #218380;
  --accent-color: #FFBC42;
  --background-color: #dcdcddb0;
  --dark-gray: #2B3035;
  /* Album Colors */
  --debut-background: #d9ffcc;
  --debut-text: #000000;
  --fearless-background: #feeab6;
  --fearless-text: #000000;
  --speaknow-background: #d1beff;
  --speaknow-text: #000000;
  --red-background: #ff6362;
  --red-text: #000000;
  --TS1989-background: #aceeff;
  --TS1989-text: #000000;
  --reputation-background: #000000;
  --reputation-text: #ffffff;
  --lover-background: #ffc2ea;
  --lover-text: #000000;
  --folklore-background: #d8dee5;
  --folklore-text: #000000;
  --evermore-background: #ffac54;
  --evermore-text: #000000;
  --midnights-background: #000fb7;
  --midnights-text: #ffffff;
  --ttpd-background: #dcd3c3;
  --ttpd-text: #000000;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.date {
  text-decoration: "italic";
  color: gray;
}

.title {
  color: var(--primary-color);
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-title {
  text-align: center;
  margin: 25px;
  color: var(--dark-gray);
}

.button-like-text {
  background: none;
  border: none;
  cursor: pointer;
  font: inherit;
  padding: 0;
  margin: 4px;
  align-items: center;
}



/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
  margin-bottom: 4rem;
}

/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 3rem;
  z-index: 10;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  height: 32rem;
}


/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}

/* rtl:begin:ignore */
.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}

/* rtl:end:ignore */


/* Featurettes
------------------------- */

.featurette-divider {
  margin: 5rem 0;
  /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
/* rtl:begin:remove */
.featurette-heading {
  letter-spacing: -.05rem;
}

/* rtl:end:remove */

/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {

  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
}

.unclickable-button {
  pointer-events: none;
  /* Disable pointer events */
  /* Copy Bootstrap button styles */
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: not-allowed;
  /* Optional: Change cursor to indicate non-clickable */
}

.debut {
  background-color: var(--debut-background);
  color: var(--debut-text);
}

.fearless {
  background-color: var(--fearless-background);
  color: var(--fearless-text);
}

.speaknow {
  background-color: var(--speaknow-background);
  color: var(--speaknow-text);
}

.red {
  background-color: var(--red-background);
  color: var(--red-text);
}

.TS1989 {
  background-color: var(--TS1989-background);
  color: var(--TS1989-text);
}

.reputation {
  background-color: var(--reputation-background);
  color: var(--reputation-text);
}

.lover {
  background-color: var(--lover-background);
  color: var(--lover-text);
}

.folklore {
  background-color: var(--folklore-background);
  color: var(--folklore-text);
}

.evermore {
  background-color: var(--evermore-background);
  color: var(--evermore-text);
}

.midnights {
  background-color: var(--midnights-background);
  color: var(--midnights-text);
}

.ttpd {
  background-color: var(--ttpd-background);
  color: var(--ttpd-text);
}

.folkmore {
  background-color: var(--folklore-background);
  color: var(--folklore-text);
}

.other {
  background-color: #FBE0D5;
  color: #000000;
}

.row-title {
  font-size: larger;
  font-weight: bold;
  margin: 5px;
}

.temporary {
  font-style: italic;
  background-color: #efefef;
}

.setlistTemp {
  background-color: #fceff5;
  color: #000000;
}

.setlist {
  background-color: #f8d2e2;
  color: #000000;
}

.surpriseSong {
  background-color: #71dbd8;
  color: #000000;
}

.primaryColor {
  background-color: var(--primary-color);
  color: #ffffff;
}

.secondaryColor {
  background-color: var(--secondary-color);
  color: #ffffff;
}

.tertiaryColor {
  background-color: var(--tertiary-color);
  color: #ffffff;
}

.accentColor {
  background-color: var(--accent-color);
  color: #000000;
}

.concert {
  background-color: #ed8bb3;
  color: #000000;
}

.release {
  background-color: #effd5f;
  color: #000000;
}

.football {
  background-color: #825736;
  color: #ffffff;
}

.ceremony {
  background-color: #000fb7;
  color: #ffffff;
}

.scrollable-container {
  width: 95%;
  overflow: auto;
  border: 1px solid #ccc;
  /* Optional: Add a border for better visibility */
}

.carousel-item-small {
  height: auto; /* or set a specific height */
}

.aboutUs {
  background-color: #FAF0F4;
  color: #000000;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
}

.aboutWillow {
  background-color: #EFFBFB;
  color: #000000;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
}

.willowTitle {
  color: var(--tertiary-color)
}

.tableCell {
  /* Default style for larger screens */
  max-width: 100%;
}

/* Media query for phones */
@media only screen and (max-width: 767px) {
  .tableCell {
      max-width: 100%;
  }
}

.react-calendar {
  width: 100% !important;
  max-width: none !important;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.react-calendar__tile {
  height: auto !important;
}
